var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SlickList',{staticClass:"resEleccombList",attrs:{"lock-to-container-edges":false,"lock-axis":"x","distance":30,"axis":"x"},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},_vm._l((_vm.list),function(item,index){return _c('SlickItem',{key:'resEleccombList' + index,attrs:{"index":index}},[_c('div',{class:[
        'resEleccombList-item',
        _vm.resEleccombId == item.id ? 'active' : '',
      ]},[_c('div',{staticStyle:{"padding":"0 18px"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(item)}}},[(
            (item.id === 98 || item.id === 99) && _vm.resEleccombId != item.id
          )?_c('img',{attrs:{"src":require("@/assets/imgs/academic/collection.png"),"alt":""}}):_vm._e(),(
            (item.id === 98 || item.id === 99) && _vm.resEleccombId == item.id
          )?_c('img',{attrs:{"src":require("@/assets/imgs/academic/collection_active.png"),"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")])])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }