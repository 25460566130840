<template>
  <SlickList
    v-model="list"
    :lock-to-container-edges="false"
    class="resEleccombList"
    lock-axis="x"
    :distance="30"
    axis="x"
  >
    <SlickItem
      v-for="(item, index) in list"
      :key="'resEleccombList' + index"
      :index="index"
    >
      <div
        :class="[
          'resEleccombList-item',
          resEleccombId == item.id ? 'active' : '',
        ]"
      >
        <div style="padding: 0 18px" @click.stop="handleClick(item)">
          <img
            v-if="
              (item.id === 98 || item.id === 99) && resEleccombId != item.id
            "
            src="@/assets/imgs/academic/collection.png"
            alt=""
          />
          <img
            v-if="
              (item.id === 98 || item.id === 99) && resEleccombId == item.id
            "
            src="@/assets/imgs/academic/collection_active.png"
            alt=""
          />
          {{ item.name }}
        </div>
      </div>
    </SlickItem>
  </SlickList>
</template>

<script>
import { SlickList, SlickItem } from "vue-slicksort";
export default {
  components: {
    SlickItem,
    SlickList,
  },
  props: {
    resEleccombList: {
      default: () => [],
      type: Array,
    },
    resEleccombId: {
      default: null,
      type: [Number, String, null],
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    resEleccombList: {
      deep: true,
      handler(val) {
        if (val.length >= 2) {
          this.list = [{ name: "全部", id: 0 }, ...val];
        } else {
          this.list = [...val];
        }
      },
    },
  },
  methods: {
    handleClick(item) {
      this.$emit("change", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.resEleccombList {
  border: 1px solid #ebecee;
  box-sizing: border-box;
  padding: 12px 18px;
  border-bottom: none;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px 4px 0px 0px;
  flex-wrap: wrap;
}
.resEleccombList-item {
  height: 32px;
  border-radius: 4px;
  border: 1px solid #d5d6db;
  line-height: 32px;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  // padding: 0 18px;
  cursor: pointer;
  background-color: #fff;
  color: #0a1119;
}
.active {
  border-color: #2474ed;
  color: #2474ed;
}
</style>
